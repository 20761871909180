import React, { Component } from 'react'
import logo from '../../asset/images/logo.png';
import imageLogin from '../../asset/images/imagenBase.jpg';

import { requestDeleteDataAccount } from '../../services/usuarioServ';

import {setDataUser,setDataToken} from '../../services/SessionServ';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import MensajeAlert from '../../components/MensajeAlert';
import { withTranslation } from 'react-i18next';

class RequestDeleteAccount extends Component {

   constructor(props) {
        super(props);
        this.state = {
            validated: false,
            textoMensaje:"",
            usuario:{correo:"",motivo:""},
            variante:"danger"
        };
   }

   myChangeHandler = (event) => {

    const { usuario } = this.state;
   
    let nam = event.target.name;
    let val = event.target.value;

    usuario[nam]=val;

    this.setState({usuario: usuario});
  }

  handleSubmit = (event) => {
    this.setState({ validated: false,textoMensaje:"" });
    const { usuario } = this.state;
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
        this.setState({ validated: true });
        event.preventDefault();
        event.stopPropagation();
    }else{
        requestDeleteDataAccount(usuario).then(result => {           
            this.setState({ validated:false  , textoMensaje:`Su Solicitud de Eliminación de Datos de Cuenta ha sido Enviada.`,variante:"success" ,usuario:{correo:"",motivo:""} });
        }).catch((error)=>{
            this.setState({ validated:false  , textoMensaje:`No se logro acceder a la plataforma`,variante:"danger"  });
        });
        event.preventDefault();
    }
};

  render () {
    const { usuario , textoMensaje,validated,variante} = this.state;
    const  {t}=this.props;
    return (
            <Container fluid="md" className="p-5">
                    <Row className="justify-content-md-center">                
                    <Card style={{"width":"25rem"}}>                
                            <Card.Img variant="top" src={imageLogin} />
                            <Card.Body>
                                    <Col className="col text-center">
                                            <h1>{t('front.deleteAccount.tittle.text')}</h1>
                                            { textoMensaje!==""?<MensajeAlert mensaje={textoMensaje} variante={variante} />:<></> }  
                                            <Form className="p-3 text-center" noValidate validated={validated} onSubmit={this.handleSubmit} >                  
                                                <FloatingLabel  controlId="floatingInputCorreo"  label={t('front.deleteAccount.email.label')}  className="mb-3" >
                                                    <Form.Control required type="email" name="correo" placeholder="Correo" value={usuario.correo} onChange={this.myChangeHandler} />
                                                </FloatingLabel>
                                                <FloatingLabel  controlId="floatingInputPass"  label={t('front.deleteAccount.reason.label')}  className="mb-3" >
                                                    <Form.Control required type="text" name="motivo" placeholder="Motivo" value={usuario.motivo} onChange={this.myChangeHandler} />
                                                </FloatingLabel>  
                                                <div className="d-grid gap-2 d-md-block m-3 text-center">                                                           
                                                    <button type="submit" value="Solicitar" className="btn btn-success mx-1">{t('front.deleteAccount.button.send.text')}</button> 
                                                </div>                     
                                            </Form> 
                                    </Col> 
                            </Card.Body>
                            </Card>  
                    </Row>    
                    <Row className="justify-content-md-center"> 
                        <p>Está solicitud de eliminación de cuenta tiene las siguientes caracteristicas:</p>
                        <ul>
                            <li>Debe completar los campos descritos en este formulario.</li>
                            <li>Este proceso tiene un périodo de aplicación entre 24 a 36 horas.</li>
                            <li>Se eliminará todos los datos de la cuenta registrada tales como: Nombres, apellidos, correo, fecha de nacimiento.</li>
                            <li>Luego de eliminado estos datos, es irreversible la recuperación de los mismos.</li>
                        </ul>
                    </Row>                                   
            </Container>
    );
  }
}

export default withTranslation()(RequestDeleteAccount);