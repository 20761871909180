import React from 'react';
import { ButtonTop } from './components/buttonTop';
import { Footer } from './components/Footer';
import { ModalFade } from './components/ModalFade';
import { Header } from './components/header';
import { MobileMenu } from './components/mobileMenu';
import  CarroCompra from './components/carroCompra';
import { PrincipalContainer } from './components/principalContainer';
import { constantes } from './components/constantes';
import { getParametrosSistemaBySeccion } from './services/parametroSistemaServ';
import { Switch, Route } from 'react-router-dom';
import  NotFoundInner from "./components/notFoundInner";
import  InternoMaster from './components/internoMaster';
import  ContenidoMaster from './components/contenidoMaster';
import  ProductoMaster from './components/productoMaster';
import  BuscarMaster from './components/buscarMaster';
import requireAuth from "./components/AuthComponent";
import WhatsAppIcon from "./components/WhatsAppIcon";
import RequestDeleteAccount from "./pages/requestDeleteAccount/RequestDeleteAccount";

class Site extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
          listContenido:[],
      };
  }

  componentDidMount() {
    this.listarParametros();         
 }

 listarParametros(){
  getParametrosSistemaBySeccion(constantes.variableSeccionContenido).then(result => {
       let  datos = result.data.data;
       let secciones = datos[0].valor.split(",");
       this.setState({ listContenido:secciones});
  }).catch((error)=>{
   this.setState({ cargando:false , mensaje:true , textoMensaje:`Ocurrio un error en el proceso ${error}` , variante:"danger" });
   });
}

 

  render(){ 
    
    const { listContenido } = this.state;

     /**
   * 
   * <Route path="/carrito-compra*" exact component={CarroCompra} />     
   *    <Route path="/producto*" exact component={ProductoMaster} />  
                <Route path="/buscar/*"  exact component={BuscarMaster}  />  
                <Route path="/usuario*" exact component={requireAuth(InternoMaster)} />  
   * 
   */
        
    return (
      <div className="App">
          <div id="page">
              <Header  { ...this.props } listContenido={listContenido} />
              <Switch>        
                <Route path='/requestDeleteAccount' component={RequestDeleteAccount} />                         
                <Route path="/contenido/*" exact component={ContenidoMaster} />                                      
                <Route path="/principal/*" exact render={(props) => ( <PrincipalContainer listContenido={listContenido}/>)} /> 
                <Route path="/" exact render={(props) => ( <PrincipalContainer listContenido={listContenido}/>)} />                               
                <Route  component={NotFoundInner}/>
              </Switch>
              <Footer listContenido={listContenido}/>       
              <ButtonTop listContenido={listContenido}/> 
              <WhatsAppIcon/>            
          </div>                          
    </div>
    );
  }
}
export default Site;
