import API from './api';

import {constantes}  from '../components/constantes';

import { filterQueryString } from '../components/Utils';

export async function getUsuarios(pagination,row,filter){

    //let tokenSeguridad = localStorage.getItem('_access_token_');
    //let id = localStorage.getItem('_id');

   // let axiosConfig = {
  //      headers: {
  //          'Authorization' : tokenSeguridad
  //      }
  //  };
  
    try{
        const response = await API.get(`/usuario?pagination=${pagination}&row=${row}${filter==null?"":"&"+filterQueryString(filter)}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response;
    } catch( error ){
        throw new Error(error);
    }
}


export async function updateEstatusUsuario(usuario){
    try{
        const response = await API.get(`/usuario/estatus/${usuario.idUsuario}/${usuario.estatus}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function  deleteUsuario(usuario){
    try{
        const response = await API.delete(`/usuario/${usuario.idUsuario}`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function addUsuario(usuario){
    try{

        const response = await API.post(`/usuario`, usuario , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function getUsuarioById(idUsuario)
{
    try{
        const response = await API.get(`/usuario/${idUsuario}` , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function login(usuario){
    try{
        const response = await API.post(`/public/${constantes.canal}/auth`, usuario , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function envioLinkReseteo(usuario){
    try{
        const response = await API.get(`/usuario/${usuario.correo}/${constantes.canal}/enviarLinkResetPassword`, constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function reseteoPass(usuario,file){
    try{
        const response = await API.post(`/usuario/reseteoPass`, usuario , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function listaOperadores(idUsuario){
    try{
        const response = await API.get(`/operador/${idUsuario}/${constantes.listaPerfiles[0]}/operadores`,constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function uploadImagenPerfil(idUsuario,file){
    try{
        const headers = {
            'Content-Type': "multipart/form-data",
        };
        const response = await API.post(`/usuario/${idUsuario}/fileUpload`, file ,headers);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function registrarUsuario(usuario){
    try{
        const response = await API.post(`/public/${constantes.canal}/register`, usuario , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function activarUsuario(usuario){
    try{
        const response = await API.post(`/usuario/${constantes.canal}/activarUsuario`, usuario , constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function subscribirANoticia(correo){
    try{
        const response = await API.get(`/usuario/subscribirANoticias/${correo}`,constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function solicitudInformacionPlataforma(correo){
    try{
        const response = await API.get(`/usuario/solicitudInformacionPlataforma/${correo}`,constantes.axiosConfig);
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}

export async function requestDeleteDataAccount(request){
    try{
        const response = await API.post(`/usuario/requestDeleteDataAccount`, request );
        if(response.data.estatus!==constantes.estatusOKAServ){
            throw new Error(response.data.errores[0].mensajeError);
        }
        return response.data;
    } catch( error ){
        throw new Error(error);
    }
}